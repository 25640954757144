<template>
  <section class="push-list">
    <section class="push-filter">
      <el-form :inline="true" :model="form" class="demo-form-inline">
        <el-form-item label="关键词">
          <el-input v-model="form.keyword" size="small" placeholder="搜索通知标题或通知内容"></el-input>
        </el-form-item>
        <el-form-item label="时间">
          <el-date-picker v-model="form.createDate" unlink-panels size="small" type="datetimerange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit" size="mini">查询</el-button>
        </el-form-item>
      </el-form>
    </section>
    <section class="push-content">
      <div class="btn-wrap">
        <el-button size="small" type="primary" @click="addPush">创建</el-button>
      </div>
      <el-table
        :data="tableData"
        @row-click="addList"
        border max-height="calc(100% - 40px)"
        ref="table"
        style="width: 100%;margin-bottom: 60px;" v-loading="loading">
        <el-table-column  :resizable="false"
          prop="messageTitle"
          align="center"
          label="通知标题">
        </el-table-column>
        <el-table-column  :resizable="false"
        prop="message"
          align="center"
          label="通知内容">
        </el-table-column>
        <el-table-column  :resizable="false"
          prop="isEnable"
          align="center"
          label="是否展示">
          <template scope="{ row }">
            <el-switch
              :active-value=0
              :inactive-value=1
              @change="updatePushStatus(row.id, row.status)"
              style="display: block"
              v-model="row.status"
              active-color="#13ce66"
              inactive-color="#ff4949">
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column  :resizable="false"
          prop="createDates"
          align="center"
          label="创建时间">
        </el-table-column>
        <el-table-column  :resizable="false"
          label="操作"
          align="center">
        <template scope="{ row }">
          <el-button type="text" @click="editPush(row.id)">修改</el-button>
          <el-button type="text" @click="deletePush(row.id)">删除</el-button>
        </template>
        </el-table-column>
      </el-table>
      <section class="list-bottom">
        <span class="count">共{{count}}条记录</span>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[10, 20, 30, 50]"
          :page-size="size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="count">
        </el-pagination>
      </section>
    </section>
    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      width="30%">
      <span class="prompt"><i class="el-icon-warning"></i>确定删除？</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="deletePushSet">确 定</el-button>
      </span>
    </el-dialog>
  </section>
</template>

<script>
import eventHub from '@/utils/eventHub'
import { listPushSet, updateSystemInformationStatus, dlectSystemInformation, selectSystemInformation, selectCountSystemInformation, updateSyspushStatus, deleteSyspush, listSyspushCount } from '@/api/settings/push'
export default {
  data () {
    return ({
      id: 0,
      count: 2,
      form: {
        keyword: '',
        createDate: [],
        size: 10,
        currentPage: 1,
        startDate: '',
        endDate: ''
      },
      tableData: [],
      selectedList: [],
      currentPage: 1,
      dialogVisible: false,
      size: 10,
      loading: false
    })
  },
  methods: {
    handleSizeChange (val) {
      this.size = val
      this.form.size = val
      this.listAllPushSet()
    },
    handleCurrentChange (val) {
      this.currentPage = val
      this.form.currentPage = val
      this.listAllPushSet()
    },
    addList (row) {
      let index = null
      this.tableData.forEach((item, i) => {
        if (item.id === row.id) {
          index = i
        }
      })
      if (!this.selectedList.includes(row.id)) {
        this.$refs.table.bodyWrapper.querySelector(`tbody .el-table__row:nth-child(${index + 1})`).classList.add('selected')
        this.selectedList.push(row.id)
      } else {
        this.$refs.table.bodyWrapper.querySelector(`tbody .el-table__row:nth-child(${index + 1})`).classList.remove('selected')
        this.selectedList.splice(this.selectedList.indexOf(row.id), 1)
      }
    },
    onSubmit () {
      this.listAllPushSet()
    },
    deletePush (id) {
      this.id = id
      this.dialogVisible = true
    },
    addPush () {
      this.$router.push('/settings/push/add')
    },
    editPush (id) {
      this.$router.push('/settings/push/edit/' + id)
    },
    async listAllPushSet () {
      this.loading = true
      if(this.form.createDate.length > 0){
        this.form.startDate = this.formatDate(this.form.createDate[0])
        this.form.endDate = this.formatDate(this.form.createDate[1])
      }
      this.form.size = this.size
        this.form.currentPage = this.currentPage
      // const res = await listPushSet(this.form)
      // const count = await listSyspushCount()
      const res = await selectSystemInformation(this.form)
      const count = await selectCountSystemInformation(this.form)
      this.count = count.data
      this.tableData = res.data
      this.loading = false
    },
    async updatePushStatus (id, status) {
      // const res = await updateSyspushStatus({ syspushId: id, isEnable: status })
      const res = await updateSystemInformationStatus({ id: id, status: status })
      if (res.code === 0) {
        this.$message.success(res.msg)
      } else {
        this.$message.error(res.msg)
      }
    },
    async deletePushSet () {
      // const res = await deleteSyspush({ syspushId: this.id })
      const res = await dlectSystemInformation({ id: this.id })
      if (res.code === 0) {
        this.$message.success('删除成功')
        this.dialogVisible = false
        this.listAllPushSet()
      } else {
        this.$message.error('删除失败')
      }
    },
    formatDate (time) {
      let date = new Date(time);
      let year = date.getFullYear()
      let month = date.getMonth() + 1
      let day = date.getDate()
      let hour = date.getHours()
      let min = date.getMinutes()
      let second = date.getSeconds()
      if (month < 10) {
        month = '0' + month
      }
      if (day < 10) {
        day = '0' + day
      }
      if (hour < 10) {
        hour = '0' + hour
      }
      if (min < 10) {
        min = '0' + min
      }
      if (second < 10) {
        second = '0' + second
      }
      return year + '-' + month + '-' + day + ' ' + hour + ':' + min + ':' + second
    },
  },
  mounted () {
    this.listAllPushSet()
    eventHub.$emit('msg', '系统推送规则')
  }
}
</script>

<style lang="scss">
.push-list {
  width: 100%;
  height: 100%;
  .push-filter {
    width: 100%;
    height:80px;
    background: #FFFFFF;
    color: #303133;
    border-radius: 2px;
    // box-shadow:0px 1px 13px 0px rgba(0, 0, 0, 0.1);
    margin-bottom: 15px;
    .el-form {
      padding-left: 24px;
      .el-form-item {
        margin-bottom: 0;
        margin-top: 20px;
        margin-right: 30px;
        &:nth-child(2) {
          margin-right: 30px;
        }
      }
    }
  }
  .push-content {
    width: 100%;
    height: calc(100% - 175px);
    background: #FFFFFF;
    border-radius: 2px;
    // box-shadow:0px 1px 13px 0px rgba(0, 0, 0, 0.1);
    padding: 20px;
    position: relative;
    .selected {
      background: rgba(64,158,255, .2);;
    }
    .btn-wrap {
      margin-bottom: 25px;
    }
    .list-bottom {
      display: flex;
      justify-content: space-between;
      position: absolute;
      bottom: 20px;
      left: 20px;
      right: 20px;
      .count {
        line-height: 32px;
      }
    }
  }
  .el-dialog {
    .prompt {
      height: 28px;
      display: flex;
      align-items: center;
      .el-icon-warning {
        font-size: 28px;
        color: #FF8B35;
        margin-right: 12px;
      }
    }
  }
}
</style>
